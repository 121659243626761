import React from 'react'
import PropTypes from 'prop-types'

const IllustratedPoint = ({
  image,
  title,
  description,
  hasAdditionalMarginTop,
  sizeClass,
}) => {
  const wrapperClasses = [
    'column is-4-desktop mt-50-touch is-10-tablet is-offset-1-tablet is-offset-0-desktop is-12-mobile',
  ]
  if (hasAdditionalMarginTop) {
    wrapperClasses.push('mt-75')
  }
  const figureClasses = ['image mb-1']
  if (sizeClass) {
    figureClasses.push(sizeClass)
  }
  return (
    <div className={wrapperClasses.join(' ')}>
      <figure className={figureClasses.join(' ')}>
        <img
          src={
            !!image.src.childImageSharp
              ? image.src.childImageSharp.fluid.src
              : image.src
          }
          alt={image.alt}
        />
      </figure>
      <h3 className="my-4 has-text-centered">{title}</h3>
      <p className="body-small has-text-centered">{description}</p>
    </div>
  )
}

IllustratedPoint.propTypes = {
  hasAdditionalMarginTop: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    alt: PropTypes.string,
  }),
}

export default IllustratedPoint
