import React from 'react'
import PropTypes from 'prop-types'

const Quote = ({ text, author }) => {
  return (
    <section className="section is-medium light-pink-background">
      <div className="container">
        <div className="columns">
          <div className="column is-8-tablet is-offset-2-tablet is-12-mobile testimonial">
            <div className="testimonial-content">
              <p className="like-h3">{text}</p>
              <p className="body-small mt-4 author">{author}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Quote.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
}

export default Quote
